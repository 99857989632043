/* reusing css / theme values */
/* currently have classes setup on index.css */
/* option 1: use the class names (limited) */
/* option 2: use less for mixins */
/* option 3: react context with inline styles / reusable components */
/* option 4: mui themes */
/* option 5: could styled components do this? */

.container {
  min-height: 100vh;
  margin: 0;
}

@media (min-width: 700px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 500px 1fr;
    grid-template-rows: auto 1fr auto;
  }
}

@media (max-width: 699px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
    grid-template-rows: auto 1fr auto;
  }
}

@media (max-width: 400px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}

.padding-left {
  padding-left: 16px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: span 3;
}

.padding {
  padding: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}



/* header */

header {
  display: flex;
  grid-column: span 3;
  text-align: center;
  font-size: 1em;
  color: white;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 16px;
}

/* @media (min-width: 700px) {
  header {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
  }
}

@media (max-width: 699px) {
  header {
    display: flex;
    flex-direction: column;
  }
} */



/* background */

.container {
  background-image:  url('./wavey.png');
}